import Image from 'next/image';
import Link from 'next/link';

export function Categories({ categories }) {
  return (
    <div className='container py-6 px-4 mx-auto'>
      <div className='grid grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6'>
        {categories.map((category) => (
          <Link key={category.sys.id} href={`atrativos#${category.slug}`}>
            <div className='flex items-center gap-4'>
              <div className='flex-none w-16 md:w-20 2xl:w-24'>
                <div className='flex'>
                  <Image
                    src={category.icon.url}
                    alt=''
                    width='100'
                    height='100'
                  />
                </div>
              </div>
              <div className='flex-grow'>
                <h3
                  className={`font-bold text-xl md:text-2xl max-w-36 text-${category.color}`}
                >
                  {category.name}
                </h3>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Categories;
