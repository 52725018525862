import { useEffect, useState } from "react"
import axios from 'axios';

import { 
  WiDaySunny,
  WiDaySunnyOvercast,
  WiCloud,
  WiCloudy,
  WiDayRain,
  WiDayShowers,
  WiDayThunderstorm,

  WiNightClear,
  WiNightCloudy,
  WiNightRain,
  WiNightShowers,
  WiNightThunderstorm
} from 'react-icons/wi'

import styled from './Weather.module.css'

const ICON_MAP = {
  // day
  "01d": <WiDaySunny />,
  "02d": <WiDaySunnyOvercast />,
  "03d": <WiCloud />,
  "04d": <WiCloudy />,
  "09d": <WiDayShowers />,
  "10d": <WiDayRain />,
  "11d": <WiDayThunderstorm />,
  // night
  "01n": <WiNightClear />,
  "02n": <WiNightCloudy />,
  "03n": <WiCloud />,
  "04n": <WiCloudy />,
  "09n": <WiNightShowers />,
  "10n": <WiNightRain />,
  "11n": <WiNightThunderstorm />,
}

export function Weather(props){
  const [data, setData] = useState(null)

  useEffect(() => {
    axios.get(`https://api.openweathermap.org/data/2.5/weather?id=3452525&appid=fe0789f5ee3417074342133cec579259&lang=pt_br&units=metric`)
      .then(res => {
        setData(res.data)
      })
  }, [])

  if(!data) return null

  return(
    <div className={'flex text-current p-6 flex-col rounded-t-xl rounded-br-xl ' + styled.box}>

      <div className="relative z-50 flex items-center opacity-75">
        <div className="flex-1">
          <h5 className="border border-gray-dark rounded inline-block py-1 px-2">Pouso Alegre</h5>
        </div>
        <div>
          <a href="https://openweathermap.org/city/3452525" target="_blank" className="font-semibold">OpenWeather</a>
        </div>
      </div>

      <div className="relative z-50 flex flex-1 items-center justify-center opacity-75">
        <div className="text-center">
          <div className="text-7xl mx-auto inline-block">
            {ICON_MAP[data.weather[0].icon]}  
          </div>
          <h3 className="uppercase text-xl">{data.weather[0].description}</h3>  
        </div>
      </div>

      <div className="relative z-50 flex items-center opacity-75">
        <div className="flex-1">
          <h3>Umidade: {parseFloat(data.main.humidity).toFixed(2)}%</h3>
          <h3>Vento: {parseFloat(data.wind.speed*3.6).toFixed(2)} Km/h</h3>
        </div>
        <div>
          <h3 className="text-4xl">{data.main.temp}°</h3>
        </div>
      </div>
    </div>
  )
}

export default Weather