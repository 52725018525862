import Image from 'next/image'

const UTILS = [
  {link: 'https://www.minasgerais.com.br/pt', image: '/portal-turismo.jpg', name: 'Portal de Turismo de Minas Gerais'},
  {link: 'https://www.caminhosdamantiqueira.tur.br/', image: '/caminhos-da-mantiqueira.jpg', name: 'Circuito turístico Caminhos da Mantiqueira'},
  {link: 'https://pousoalegre.mg.gov.br/', image: '/prefeitura.jpg', name: 'Prefeitura de Pouso Alegre'}
]

export function Utils(props){
  return(
    <div className="lg:col-span-2 2xl:col-span-1">
      <h4 className="font-bold text-current text-xl mb-6">Utilidades</h4>
      <div className="grid lg:grid-cols-3 2xl:grid-cols-1 gap-4">
        {UTILS.map((item, key) =>
          <a key={key} href={item.link} target="_blank" rel="noopener">
            <div key={key} className="grid grid-cols-6 gap-4 items-center text-current">
              <div className="flex">
                <Image src={item.image} width="100" height="100" alt={item.name} className="rounded-full bg-gray-light" />
              </div>
              <div className="col-span-5">
                <h3 className="font-semibold">{item.name}</h3> 
              </div>
            </div>  
          </a>
        )}  
      </div>
      
    </div>
  )
}

export default Utils