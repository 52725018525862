import Flickity from "react-flickity-component";
import Image from "next/image";

import { Modal } from "./Modal";
import { useState } from "react";

const flickityOptions = {
  wrapAround: true,
  autoPlay: 5000,
};

export function Slideshow({ slides }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Modal open={open} setOpen={setOpen} />
      <div className="overflow-hidden bg-gray-lighten mb-6 bg-waves overflow-hidden block">
        <div className="container mx-auto px-4">
          <Flickity
            className={"slideshow-home bg-gray"}
            elementType={"div"}
            options={flickityOptions}
            disableImagesLoaded={false}
            reloadOnUpdate
            static
          >
            <div className="w-full flex -mb-3">
              <Image
                src="/video-home.jpg"
                alt="Vídeo"
                width="1520"
                height="630"
                quality="100"
                className="bg-gray-light cursor-pointer"
                onClick={() => setOpen(true)}
              />
            </div>
            {slides.map((slide, key) => (
              <div key={key} className="w-full flex">
                <Image
                  src={slide.image.url}
                  alt=""
                  width="1520"
                  height="630"
                  quality="100"
                  className="bg-gray-light"
                />
              </div>
            ))}
          </Flickity>
        </div>
      </div>
    </>
  );
}

export default Slideshow;
