import Image from 'next/image';
import Link from 'next/link';
import Flickity from 'react-flickity-component';
import { useRef } from 'react';
import { FiPlusCircle } from 'react-icons/fi';

import { ArrowLeft, ArrowRight } from '../../layout/arrows';

import styled from './Attractions.module.css';
import { ImageCustom } from '../../layout';

const flickityOptions = {
  prevNextButtons: false,
  pageDots: false,
  groupCells: true,
};

function Attraction(props) {
  return (
    <div className={styled.slide}>
      <Link href={`/atrativos/${props.sys.id}`}>
        <div>
          <ImageCustom url={props.thumbnail.url} name={props.name} />
          <h3 className='font-bold font-serif text-md sm:text-xl text-current mt-3 sm:mt-4'>
            {props.name}
          </h3>
        </div>
      </Link>
    </div>
  );
}

export function Attractions({ attractions }) {
  const flickity = useRef(null);

  const handlePrev = (e) => {
    flickity.current.previous();
  };

  const handleNext = (e) => {
    flickity.current.next();
  };

  return (
    <div className='lg:mb-6'>
      <div className='container mx-auto px-4 py-6 lg:mb-4'>
        <div className='flex items-center'>
          <div className='flex-grow'>
            <h3 className='uppercase text-lightBlue font-bold text-md lg:text-lg mb-2'>
              Venha visitar Pouso Alegre
            </h3>
            <h2 className='font-bold text-3xl lg:text-4xl xl:text-5xl text-current font-serif'>
              Conheça nossos atrativos
            </h2>
          </div>
          <div className='relative flex'>
            <ArrowLeft onClick={handlePrev} />
            <ArrowRight onClick={handleNext} />
          </div>
        </div>
      </div>
      <div>
        <Flickity
          flickityRef={(c) => (flickity.current = c)}
          className={'attractions'}
          elementType={'div'}
          options={flickityOptions}
          disableImagesLoaded={false}
          reloadOnUpdate
          static
        >
          {attractions.map((attraction, key) => (
            <Attraction key={key} {...attraction} />
          ))}
          <div className={styled.slide}>
            <Link href='/atrativos'>
              <div className='aspect-w-10 aspect-h-10 bg-lightBlue hover:bg-lightBlue-dark transition w-full rounded-t-2xl rounded-br-2xl'>
                <div className='flex items-center justify-center overflow-hidden'>
                  <div className='text-white'>
                    <FiPlusCircle className='mx-auto mb-4 text-5xl' />
                    <h3 className='text-xl md:text-2xl xl:text-3xl font-bold'>
                      Clique aqui
                      <br />e veja mais
                    </h3>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </Flickity>
      </div>
    </div>
  );
}

export default Attractions;
