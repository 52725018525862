import News from './News'
import Utils from './Utils'
import Weather from './Weather'

export function Footer({ newspappers }){
  return(
    <div className="container mx-auto px-4 mb-10 xl:mb-14">
      <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-6 xl:gap-8 2xl:gap-10">
        <Weather />
        <News news={newspappers} />
        <Utils />
      </div>
    </div>
  )
}

export default Footer