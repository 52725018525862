import Link from "next/link";
import Image from "next/image";

export function History() {
  return (
    <div className="text-current container mx-auto px-4 py-10">
      <div className="grid grid-cols-1 lg:grid-cols-2 items-center">
        <div className="grid grid-cols-12 items-center mb-10 lg:mb-0 lg:pr-10 xl:pr-20 -mx-3">
          <div className="col-span-5">
            <div className="flex p-3">
              <Image
                width="500"
                height="500"
                alt="Pouso Alegre"
                className="rounded-t-xl rounded-bl-xl bg-gray-light"
                src="/cidade_4.jpg"
              />
            </div>
            <div className="flex p-3 w-3/5 ml-auto mr-0">
              <Image
                width="500"
                height="500"
                alt="Pouso Alegre"
                className="rounded-b-xl rounded-tl-xl bg-gray-light"
                src="/cidade_1.jpg"
              />
            </div>
          </div>
          <div className="col-span-7">
            <div className="flex p-3 w-2/3">
              <Image
                width="500"
                height="500"
                alt="Pouso Alegre"
                className="rounded-t-xl rounded-br-xl bg-gray-light"
                src="/cidade_2.jpg"
              />
            </div>
            <div className="flex p-3">
              <Image
                width="500"
                height="500"
                alt="Pouso Alegre"
                className="rounded-b-xl rounded-tr-xl bg-gray-light"
                src="/cidade_3.jpg"
              />
            </div>
          </div>
        </div>
        <div>
          <h2 className="font-bold text-4xl lg:text-5xl font-serif mb-8">
            Conheça a história
            <br />
            de Pouso Alegre
          </h2>
          <div>
            <p>
              Localizada num dos principais entroncamentos rodoviários da
              região, Pouso Alegre é uma cidade de vanguarda, evidenciada pela
              sua dinâmica social e econômica, que a torna o município que mais
              cresce atualmente no Sul de Minas. Possui uma história pujante,
              revelada nos seus inúmeros edifícios históricos e por uma rica
              arquitetura eclesiástica, que apresenta estilos arquitetônicos
              variados e quantidades de Igrejas que se equiparam as das Cidades
              Históricas de Minas Gerais.
            </p>
          </div>
          <Link href="/historia">
            <button className="bg-lightBlue hover:bg-lightBlue-dark text-white font-semibold px-10 py-3 text-xl rounded-md">
              Leia mais
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default History;
