import Flickity from 'react-flickity-component';
import { useRef } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { ArrowLeft, ArrowRight } from '../../layout/arrows';
import styled from './Events.module.css';

const flickityOptions = {
  prevNextButtons: false,
  pageDots: false,
  groupCells: true,
};

function Event({ event }) {
  return (
    <div className='w-full lg:w-1/2 pr-6'>
      <div className='grid grid-cols-5 gap-6 items-center'>
        <div className='col-span-2 aspect-w-1 aspect-h-1'>
          <Image
            src={event.thumbnail.url}
            width='640'
            height='640'
            className='rounded-t-2xl rounded-br-3xl bg-gray-light'
          />
        </div>
        <div className='col-span-3'>
          <h5 className='font-bold text-sm uppercase text-lightBlue'>
            {event.date}
          </h5>
          <Link href={`/calendario/${event.id}`}>
            <h2 className='hover:text-blue font-serif font-bold text-current text-2xl md:text-3xl lg:text-2xl xl:text-3xl py-2'>
              {event.name}
            </h2>
          </Link>
          <p className='hidden sm:block lg:hidden xl:block font-semibold text-light mb-3'>
            {event.shortDescription}
          </p>
          <Link href={`/calendario/${event.sys.id}`}>
            <span className='bg-blue hover:bg-blue-dark px-3 py-2 font-semibold rounded text-white text-sm'>
              Saiba mais...
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export function Events({ events }) {
  const flickity = useRef(null);

  const handlePrev = (e) => {
    flickity.current.previous();
  };

  const handleNext = (e) => {
    flickity.current.next();
  };

  return (
    <>
      <div className='container mx-auto px-4 py-6 lg:mb-4'>
        <div className='flex items-center'>
          <div className='flex-grow'>
            <h3 className='uppercase text-lightBlue font-bold text-md lg:text-lg mb-2'>
              Lazer e diversão
            </h3>
            <h2 className='font-bold text-3xl lg:text-4xl xl:text-5xl text-current font-serif'>
              Principais eventos da cidade
            </h2>
          </div>
          <div className='relative flex'>
            <ArrowLeft onClick={handlePrev} />
            <ArrowRight onClick={handleNext} />
          </div>
        </div>
      </div>
      <div className={styled.events}>
        <Flickity
          flickityRef={(c) => (flickity.current = c)}
          className={'events container mx-auto px-4 py-8'}
          elementType={'div'}
          options={flickityOptions}
          disableImagesLoaded={false}
          reloadOnUpdate
          static
        >
          {events.map((event, key) => (
            <Event key={key} event={event} />
          ))}
        </Flickity>
      </div>
    </>
  );
}

export default Events;
