import Link from 'next/link';

export function Mapa() {
  return (
    <Link href='/mapa'>
      <div className='container mx-auto px-4 mb-10 xl:mb-14 position-relative'>
        <div
          style={{ backgroundImage: 'url(bg-map.jpg)' }}
          className={'bg-cover md:aspect-w-10 md:aspect-h-4 rounded-xl '}
        >
          <div className='w-full h-full px-6 py-10 flex items-center justify-center'>
            <div className='text-center text-white max-w-screen-md'>
              <h2 className='text-4xl lg:text-5xl font-bold font-serif mb-4'>
                Mapa virtual de Pouso Alegre
              </h2>
              <h4 className='text-xl mb-6'>
                Selecionamos os melhores lugares para você conhecer
              </h4>
              <span className='bg-lightBlue hover:bg-lightBlue-dark text-white font-semibold px-10 py-3 text-xl rounded-md'>
                Acessar
              </span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default Mapa;
