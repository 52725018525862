import { gql } from "@apollo/client";
import client from "../lib/apollo-client";
import Head from "next/head";

import {
  Slideshow,
  Categories,
  Attractions,
  Events,
  History,
  Mapa,
  Footer,
} from "../components/homepage";

export default function Home({
  slideshow,
  categories,
  attractions,
  events,
  newspappers,
}) {
  return (
    <div className="homepage">
      <Head>
        <title>Portal do Turismo - Pouso Alegre</title>
      </Head>
      <Slideshow slides={slideshow} />
      <Categories categories={categories} />
      <Attractions attractions={attractions} />
      <Events events={events} />
      <History />
      <Mapa />
      <Footer newspappers={newspappers} />
    </div>
  );
}

const query = gql`
  query Homepage($slideshowId: String!, $categoryListId: String!) {
    slideshow(id: $slideshowId) {
      slidesCollection(limit: 10) {
        items {
          sys {
            id
          }
          image {
            url
          }
        }
      }
    }
    listaDeCategorias(id: $categoryListId) {
      categoriesCollection(limit: 10) {
        items {
          sys {
            id
          }
          name
          slug
          color
          icon {
            url
          }
        }
      }
    }
    attractionCollection(limit: 11) {
      items {
        sys {
          id
        }
        name
        slug
        thumbnail {
          url
        }
      }
    }
    eventCollection(limit: 10) {
      items {
        sys {
          id
        }
        name
        slug
        date
        shortDescription
        thumbnail {
          url
        }
      }
    }
    newsCollection(limit: 2) {
      items {
        sys {
          id
        }
        name
        slug
        thumbnail {
          url
        }
      }
    }
  }
`;

export async function getStaticProps() {
  const { data } = await client.query({
    query: query,
    variables: {
      slideshowId: process.env.NEXT_PUBLIC_SLIDESHOW_ID,
      categoryListId: process.env.NEXT_PUBLIC_CATEGORY_LIST_ID,
    },
  });

  return {
    props: {
      slideshow: data?.slideshow?.slidesCollection?.items || [],
      categories: data?.listaDeCategorias?.categoriesCollection?.items || [],
      attractions: data?.attractionCollection?.items || [],
      events: data?.eventCollection?.items || [],
      newspappers: data?.newsCollection?.items || [],
    },
    revalidate: 120,
  };
}
