import ResponsiveEmbed from 'react-responsive-embed'

export function Modal({ open, setOpen }){
  if (!open) return null

  const handleClick = () => {
    setOpen(false)
  }

  return(
    <div className="fixed z-50 inset-0">
      <div className="items-center justify-center min-h-screen px-4 text-center flex">
        <div onClick={handleClick} className="fixed inset-0 bg-black bg-opacity-75 transition-opacity"></div>
        <span className="hidden inline-block align-middle h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-screen-xl w-full">
          <div className="bg-white p-4">
            <ResponsiveEmbed src='https://www.youtube.com/embed/6RXPi35pz64' ratio='16:7.5' allowfullscreen />
          </div>
          <div className="bg-gray-50 px-4 pb-4 flex flex-row-reverse">
            <button onClick={handleClick} type="button" className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-0 w-auto text-sm">
              Fechar
            </button>
          </div>
        </div>  
      </div>
    </div>
  )
}

export default Modal