import Image from 'next/image';
import Link from 'next/link';

export function News({ news }) {
  return (
    <div>
      <h4 className='font-bold text-xl text-current'>Novidades</h4>
      <div>
        {news.map((item, key) => (
          <div key={key} className='grid grid-cols-4 gap-4 mt-6'>
            <div className='aspect-w-1 aspect-h-1'>
              <Image
                src={item.thumbnail.url}
                width='200'
                height='200'
                alt={item.name}
                className='rounded-t-lg rounded-br-lg'
              />
            </div>
            <div className='col-span-3'>
              <Link href={`/agenda/${item.id}`}>
                <h3 className='text-lg font-serif font-bold mb-3 text-current'>
                  {item.name}
                </h3>
              </Link>
              <Link href={`/agenda/${item.sys.id}`}>
                <button className='bg-lightBlue hover:bg-lightBlue-dark px-3 py-1 font-semibold rounded text-white text-sm'>
                  Leia mais
                </button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default News;
